import React, { useEffect, useState } from 'react';
import JobApplyService from '../services/JobApplyService';
import Loading from './Loading';
import '../styles/SavedJobs.css';

const SavedJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSavedJobs = async () => {
            try {
                const response = await JobApplyService.getAppliedJobs();
                setJobs(response.data);
            } catch (error) {
                setError("Erreur lors de la récupération des emplois sauvegardés.");
                console.error("Erreur:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchSavedJobs();
    }, []);

    if (loading) return <Loading />;
    if (error) return <div>{error}</div>;

    return (
        <div className="saved-jobs-container">
            <h1>Emplois Sauvegardés</h1>
            {jobs.length === 0 ? (
                <p>Aucun emploi sauvegardé.</p>
            ) : (
                <div className="job-cards">
                    {jobs.map(job => (
                        <div key={job.id} className="job-card">
                            <div className="job-card-header">
                                <h2>{job.first_name} {job.last_name}</h2>
                                <p>{job.email}</p>
                            </div>
                            <div className="job-card-body">
                                <p><strong>Poste:</strong> {job.job.title}</p>
                                <p><strong>Entreprise:</strong> {job.job.company.cname}</p>
                                <p><strong>Description:</strong> {job.job.description}</p>
                                <p><strong>Rôles:</strong> {job.job.roles}</p>
                                <p><strong>Date limite de candidature:</strong> {job.job.last_date}</p>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default SavedJobs;
