import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { FaBuilding, FaBriefcase, FaNewspaper, FaUserCircle, FaUserTie, FaSignOutAlt } from 'react-icons/fa';
import Login from './Login';
import Register from './Register';
import '../styles/Navbar.css';

const Navbar = ({ isLoggedIn, handleLogout, setIsLoggedIn }) => {
  const [profileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRegister, setIsRegister] = useState(false);

  const profileMenuRef = useRef(null);
  const modalRef = useRef(null);

  const toggleProfileMenu = () => setProfileMenuOpen((prevState) => !prevState);
  const toggleModal = () => setIsModalOpen((prevState) => !prevState);
  const switchToRegister = () => setIsRegister(true);
  const switchToLogin = () => setIsRegister(false);

  const handleClickOutside = (event) => {
    if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
      setProfileMenuOpen(false);
    }
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const renderNavItems = () => (
    <>
      <li>
        <Link to="/companies" className="block py-2 text-l font-semibold text-gray-800 hover:text-blue-500 transition duration-300 flex items-center">
          <FaBuilding className="mr-2" /> <span className="hidden md:block">Société</span>
        </Link>
      </li>
      <li>
        <Link to="/jobs" className="block py-2 text-l font-semibold text-gray-800 hover:text-blue-500 transition duration-300 flex items-center">
          <FaBriefcase className="mr-2" /> <span className="hidden md:block">Offres d'emploi</span>
        </Link>
      </li>
      <li>
        <Link to="/media" className="block py-2 text-l font-semibold text-gray-800 hover:text-blue-500 transition duration-300 flex items-center">
          <FaNewspaper className="mr-2" /> <span className="hidden md:block">Média</span>
        </Link>
      </li>
      {isLoggedIn ? (
        <>
          <li>
            <Link to="/profile" className="block py-2 text-l font-semibold text-gray-800 hover:text-blue-500 transition duration-300 flex items-center">
              <FaUserCircle className="mr-2" /> Profile
            </Link>
          </li>
          <li>
            <button
              onClick={handleLogout}
              className="block w-full text-left py-2 text-l font-semibold text-red-600 hover:text-red-500 focus:outline-none flex items-center"
            >
              <FaSignOutAlt className="mr-2" /> Déconnexion
            </button>
          </li>
        </>
      ) : (
        <li>
          <button
            onClick={toggleModal}
            className="block py-2 text-l font-semibold text-white bg-blue-500 hover:bg-blue-600 transition duration-300 flex items-center px-4 py-2 rounded"
          >
            <FaUserTie className="mr-2" /> <span className="hidden md:block">Se connecter</span>
          </button>
        </li>
      )}
    </>
  );

  return (
    <>
      <nav>
        <div className="flex justify-between items-center p-2">
          <div className="text-xl">
            <Link to="/">
              <h1 className="font-medium mt-6">
                <strong className="text-blue-500 font-extrabold mr-0.5">JOB</strong>
                <strong className="text-gray-500 font-extrabold mr-0.5">LIK</strong>
              </h1>
            </Link>
          </div>

          {/* Web Menu */}
          <ul className="hidden md:flex items-center gap-4">
            {renderNavItems()}
          </ul>

          {/* Mobile Menu */}
          <ul className="flex md:hidden items-center gap-4">
            <li className="mr-2">
              <Link to="/companies" aria-label="Société" className="block text-gray-800 hover:text-blue-500 transition duration-300">
                <FaBuilding className="w-6 h-6" />
              </Link>
            </li>
            <li className="mr-2">
              <Link to="/jobs" aria-label="Offres d'emploi" className="block text-gray-800 hover:text-blue-500 transition duration-300">
                <FaBriefcase className="w-6 h-6" />
              </Link>
            </li>
            <li className="mr-2">
              <Link to="/media" aria-label="Média" className="block text-gray-800 hover:text-blue-500 transition duration-300">
                <FaNewspaper className="w-6 h-6" />
              </Link>
            </li>
            {isLoggedIn ? (
              <li className="relative" ref={profileMenuRef}>
                <button onClick={toggleProfileMenu} className="block text-gray-800 hover:text-blue-500 transition duration-300">
                  <FaUserCircle className="w-6 h-6" />
                </button>
                {profileMenuOpen && (
                  <ul className="absolute right-0 mt-2 py-2 w-48 bg-white border rounded shadow-xl profile-menu">
                    {/* <li>
                    <Link to="/dashboard" className="block px-4 py-2 text-l font-semibold text-gray-800 hover:bg-gray-200 flex items-center">
                      <FaHome className="mr-2" /> Dashboard
                    </Link>
                  </li> */}
                    <li>
                      <Link to="/profile" className="block px-4 py-2 text-l font-semibold text-gray-800 hover:bg-gray-200 flex items-center">
                        <FaUserCircle className="mr-2" /> Profile
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={handleLogout}
                        className="block w-full text-left px-4 py-2 text-l font-semibold text-red-600 hover:bg-gray-200 focus:outline-none flex items-center"
                      >
                        <FaSignOutAlt className="mr-2" /> Déconnexion
                      </button>
                    </li>
                  </ul>
                )}
              </li>
            ) : (
              <li>
                <button onClick={toggleModal} className="block py-2 text-l font-semibold text-white bg-blue-500 hover:bg-blue-600 transition duration-300 flex items-center px-4 py-2 rounded">
                  <FaUserTie className="w-6 h-6" />
                </button>
              </li>
            )}
          </ul>
        </div>
      </nav>

      {/* Modal pour Se connecter / Créer un compte */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg w-full max-w-md mx-4 relative" ref={modalRef}>
            <h2 className="text-center text-2xl font-bold mb-6">Bienvenue !</h2>

            <div className="flex justify-center border-b-2 mb-6">
              <button
                className={`px-4 py-2 focus:outline-none ${!isRegister ? 'text-blue-500 font-semibold border-b-4 border-blue-400' : 'text-gray-500'}`}
                onClick={switchToLogin}
              >
                Se connecter
              </button>
              <button
                className={`px-4 py-2 focus:outline-none ${isRegister ? 'text-blue-500 font-semibold border-b-4 border-blue-400' : 'text-gray-500'}`}
                onClick={switchToRegister}
              >
                Créer un compte
              </button>
            </div>

            {isRegister ? (
              <Register toggleModal={toggleModal} setIsLoggedIn={setIsLoggedIn} />
            ) : (
              <Login toggleModal={toggleModal} setIsLoggedIn={setIsLoggedIn} />
            )}

            {/* Partie fixe pour les recruteurs */}
            <div className="flex items-center border-b-2 justify-center mb-4 bg-gray-200 p-2 rounded">
              <span className="text-gray-600">Vous êtes recruteur ? </span>
              <button
                onClick={switchToLogin}
                className="text-black underline ml-1 hover:text-blue-500"
              >
                Se connecter
              </button>
            </div>

            <button onClick={toggleModal} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
              &times;
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;
