import axios from 'axios';
import { URL_API } from '../constants';

const SECTEUR_URL = `${URL_API}/secteurs`;

export const fetchSecteursActifs = async () => {
    try {
        const response = await axios.get(`${SECTEUR_URL}/actives`);
        return response.data.data;
    } catch (error) {
        throw new Error('Erreur lors du chargement des secteurs');
    }
};
