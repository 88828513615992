import React, { useState, useRef, useEffect } from 'react';
import { updateCV } from '../services/ProfileFormService';

const SubmitCV = ({ closeModal, showAlert }) => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const modalRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                closeModal();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeModal]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']; // PDF, DOCX, DOC
        if (file && allowedTypes.includes(file.type)) {
            setSelectedFile(file);
            setError(null);
        } else {
            setError('Veuillez sélectionner un fichier au format PDF ou Word (DOC/DOCX).');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!selectedFile) {
            setError('Veuillez sélectionner un fichier.');
            return;
        }

        const formData = new FormData();
        formData.append('resume', selectedFile);

        try {
            setLoading(true);
            setError(null);

            const message = await updateCV(formData);
            showAlert(message || 'CV mis à jour avec succès !', 'success');
            closeModal();

        } catch (error) {
            setLoading(false);
            setError(error.response ? error.response.data.message : error.message);
            showAlert(error.message || 'Une erreur est survenue.', 'danger');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="submit-cv-area" ref={modalRef}>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="cv">Télécharger votre CV</label>
                    <input
                        type="file"
                        id="cv"
                        className="form-control" accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                        required
                    />
                    {error && <p className="text-red-500">{error}</p>} {/* Afficher l'erreur ici */}
                </div>

                {loading && <p>Envoi en cours...</p>}

                <button
                    type="submit"
                    className="mt-4 bg-blue-500 text-white rounded py-2 px-4 mr-2"
                    disabled={loading}
                >
                    Soumettre votre CV
                </button>
                <button
                    onClick={closeModal}
                    type="button"
                    className="mt-4 bg-red-500 text-white rounded py-2 px-4"
                >
                    Fermer
                </button>
            </form>
        </div>
    );
};

export default SubmitCV;
