import React, { Component } from 'react';
import {
    AiFillInstagram,
    AiFillFacebook,
    AiFillTwitterCircle,
} from "react-icons/ai";

export default class Footer extends Component {
    render() {
        return (
            <footer>
                <div className="grid grid-cols-2 p-6 sm:p-8 lg:p-10 sm:grid-cols-3 lg:grid-cols-5 gap-4 bg-[#3B82F6] rounded-lg">
                    <div>
                        <a href="/">
                            <h1 className="text-lg font-medium text-white pb-1">
                                <strong className="text-white font-extrabold mr-0.5">JOBLIK</strong>
                            </h1>
                        </a>
                        <p className="text-white text-sm font-thin leading-5 opacity-90 text-start">
                            Nous aidons toujours nos chercheurs d'emploi et les entreprises à trouver les meilleurs postes et candidats.
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 sm:gap-2">
                        <span className="text-base font-semibold text-white">Entreprise</span>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            À propos de nous
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Fonctionnalités
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Actualités
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            FAQ
                        </li>
                    </div>
                    <div className="flex flex-col gap-1 sm:gap-2">
                        <span className="text-base font-semibold text-white">Ressources</span>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Mon compte
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Centre de support
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Retours
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Contactez-nous
                        </li>
                    </div>
                    <div className="flex flex-col gap-1 sm:gap-2">
                        <span className="text-base font-semibold text-white">Support</span>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Événements
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Promotions
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Démonstrations
                        </li>
                        <li className="text-white opacity-75 font-thin text-xs leading-5 hover:opacity-100">
                            Carrières
                        </li>
                    </div>
                    <div className="flex flex-col gap-1 col-span-2 sm:col-auto sm:gap-2">
                        <span className="text-base font-semibold text-white">
                            Informations de contact
                        </span>
                        <a
                            className="text-white opacity-80 hover:opacity-100 text-xs lg:text-sm"
                            href="mailto:contact@joblik.ma"
                        >
                            contact@joblik.ma
                        </a>
                        <div className="flex gap-2 mt-2">
                            <AiFillInstagram className="bg-slate-200 p-2 w-7 h-7 rounded-full text-[#3B82F6] inline-block hover:bg-white" />
                            <AiFillFacebook className="bg-slate-200 p-2 w-7 h-7 rounded-full text-[#3B82F6] inline-block hover:bg-white" />
                            <AiFillTwitterCircle className="bg-slate-200 p-2 w-7 h-7 rounded-full text-[#3B82F6] inline-block hover:bg-white" />
                        </div>
                    </div>
                </div>
                <div className="h-6"></div> {/* Réduisez la hauteur ici également si nécessaire */}
            </footer>
        )
    }
}
