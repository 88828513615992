import axios from 'axios';
import { URL_API } from '../constants';

const TYPE_URL = `${URL_API}/types`;

export const fetchTypesActifs = async () => {
    try {
        const response = await axios.get(`${TYPE_URL}/actives`);
        return response.data.data;
    } catch (error) {
        throw new Error('Erreur lors du chargement des types');
    }
};
