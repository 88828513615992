import React, { useEffect, useState } from 'react';
import JobsService from '../services/JobsService';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faClock, faBriefcase, faSearch } from '@fortawesome/free-solid-svg-icons';
import '../styles/Jobs.css';

const Offres = () => {
    const [offers, setOffers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [jobTypes, setJobTypes] = useState([]);
    const [selectedJobTypes, setSelectedJobTypes] = useState([]);
    const [selectedCities, setSelectedCities] = useState([]); // État pour les villes sélectionnées
    const [dropdownOpen, setDropdownOpen] = useState(false); // État pour le menu déroulant

    useEffect(() => {
        const fetchOffers = async () => {
            try {
                const data = await JobsService.getJobs();
                setOffers(data);
                setLoading(false);
                const uniqueJobTypes = Array.from(new Set(data.map(offer => offer.type_contrat.titre)));
                setJobTypes(uniqueJobTypes);
            } catch (error) {
                setError('Erreur lors de la récupération des offres');
                setLoading(false);
            }
        };
        fetchOffers();
    }, []);

    const uniqueCities = Array.from(new Set(offers.map(offer => offer.ville.title)))
        .map(city => {
            const numberOfVacancy = offers.filter(offer => offer.ville.title === city).length;
            return { city, numberOfVacancy };
        });

    const filteredOffers = offers.filter(offer =>
        (offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            offer.company.cname.toLowerCase().includes(searchTerm.toLowerCase())) &&
        (selectedJobTypes.length === 0 || selectedJobTypes.includes(offer.type_contrat.titre)) &&
        (selectedCities.length === 0 || selectedCities.includes(offer.ville.title))
    );

    if (loading) return <Loading />;
    if (error) return <p className="text-red-500 mt-4">{error}</p>;

    const handleJobTypeChange = (type) => {
        setSelectedJobTypes((prev) =>
            prev.includes(type) ? prev.filter(t => t !== type) : [...prev, type]
        );
    };

    const handleCityChange = (city) => {
        setSelectedCities((prev) =>
            prev.includes(city) ? prev.filter(c => c !== city) : [...prev, city]
        );
    };

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    return (
        <>
            <div className="filter-header">
                <div className="filter-options">
                    <div className="job-type-dropdown">
                        <div className="dropdown" onClick={toggleDropdown}>
                            <div className="dropdown-header">
                                <span>Type contrat</span>
                                <span className={`caret ${dropdownOpen ? 'open' : ''}`}>&#x25BC;</span>
                            </div>
                            {dropdownOpen && (
                                <div className="dropdown-content">
                                    {jobTypes.map((type, index) => (
                                        <label key={index} className="job-type-option">
                                            <input
                                                type="checkbox"
                                                value={type}
                                                onChange={() => handleJobTypeChange(type)}
                                                checked={selectedJobTypes.includes(type)}
                                            />
                                            {type}
                                        </label>
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Recherchez par job, mot-clé ou entreprise"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="search-input"
                        />
                        <button className="btn-filter" aria-label="Rechercher">
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </div>
                </div>
                <div className="active-filters">
                    {selectedJobTypes.length > 0 && (
                        <span className="filter-chip">
                            {`Type de job (${selectedJobTypes.length})`}
                            <button onClick={() => setSelectedJobTypes([])} className="remove-filter">x</button>
                        </span>
                    )}
                    {selectedCities.length > 0 && (
                        <span className="filter-chip">
                            {`Villes (${selectedCities.length})`}
                            <button onClick={() => setSelectedCities([])} className="remove-filter">x</button>
                        </span>
                    )}
                </div>
            </div>

            <section className="jobs-section">
                <div className="jobs-sidebar">
                    <h4>Villes</h4>
                    <ul>
                        {uniqueCities.map(({ city, numberOfVacancy }, index) => (
                            <li key={index}>
                                <label className="city-option">
                                    <input
                                        type="checkbox"
                                        value={city}
                                        onChange={() => handleCityChange(city)}
                                        checked={selectedCities.includes(city)}
                                    />
                                    <Link to="#" className="city-link"> {city} <span>({numberOfVacancy})</span></Link>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>

                <div className="jobs-list-container">
                    <div className="jobs-list">
                        {filteredOffers.length > 0 ? (
                            filteredOffers.map((offer) => (
                                <Link to={`/jobs/apply/${offer.id}`} className="overlay-link" key={offer.id}>
                                    <div className="job-offer">
                                        <div className="job-header">
                                            <h2 className="job-title">{offer.title}</h2>
                                            <span className="job-company">{offer.company.cname}</span>
                                        </div>
                                        <div className="job-info">
                                            <p>
                                                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-gray-500" /></p><p> {offer.ville.title}
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faClock} className="text-gray-500" /></p><p> Publié il y a {offer.posted_hours} heures
                                            </p>
                                            <p>
                                                <FontAwesomeIcon icon={faBriefcase} className="text-gray-500" /></p><p> {offer.type_contrat.titre}
                                            </p>
                                        </div>

                                        <div className="job-description">
                                            <p>
                                                {offer.description.length > 200
                                                    ? offer.description.substring(0, 200) + '...'
                                                    : offer.description}
                                            </p>
                                            <p><strong>Date limite :</strong> {offer.last_date}</p>
                                        </div>
                                        <Link to={`/jobs/apply/${offer.id}`} className="text-blue-500 hover:underline mt-2 inline-block">
                                            <button className="border-2 font-medium rounded-lg p-2 w-full bg-blue-500 text-white hover:bg-blue-700">
                                                Postuler
                                            </button>
                                        </Link>
                                    </div>
                                </Link>
                            ))
                        ) : (
                            <p>Aucune offre correspondant à votre recherche.</p>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default Offres;
