import React, { Component } from 'react';
import { BiTimeFive } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import JobsService from '../services/JobsService';
import { URL } from '../constants';
import Loading from './Loading';
import imageNotFound from '../assets/images/image-not-found.png';


export default class Jobs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        try {
            const jobs = await JobsService.getJobs();
            this.setState({ jobs, loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    render() {
        const { jobs, loading, error } = this.state;

        return (
            <section id="jobs">
                <div className="flex gap-10 justify-center flex-wrap items-left py-5 sm:py-10">
                    {loading && <Loading />}
                    {error && <p className="text-red-500 mt-4">{error}</p>}
                    {!loading && !error && jobs.slice(0, 10).map((job) => (
                        <div
                            key={job.id}
                            className="flex-grow group flex flex-col justify-between h-[350px] w-[300px] p-4 bg-white rounded-md shadow-lg"
                            style={{ minWidth: '250px', maxWidth: '300px', maxHeight: '350px' }}
                        >
                            <div className="upperpart flex justify-between items-center">
                                <div className="titlecountry flex-grow">
                                    <p className="title font-bold text-xl">
                                        {job.title}
                                    </p>
                                    <p className="text-[#8b8b8b]">
                                        {job.ville.title}
                                    </p>
                                </div>
                                <span className="text-[#8b8b8b]">
                                    <BiTimeFive className="inline mb-0.5 mr-1" />
                                    {new Date(job.last_date).toLocaleDateString()}
                                </span>
                            </div>
                            <div className="lowerpart border-t-2 mt-4 flex-grow">
                                <p className="mt-4 text-sm text-[#adaaaa]">
                                    {job.description.length > 100
                                        ? job.description.substring(0, 100) + '...'
                                        : job.description}
                                </p>
                                <div className="company flex items-center mt-4">
                                    <img
                                        className="rounded-full" src={job.company.logo ? `${URL}/${job.company.logo}` : imageNotFound}
                                        width={30}
                                        height={30}
                                        alt={job.company.cname ? `${job.company.cname} Logo` : 'Image non disponible'}
                                    />

                                    <p className="ml-2 text-sm font-medium">
                                        {job.company.cname}
                                    </p>
                                </div>
                            </div>
                            <Link to={`/jobs/apply/${job.id}`} className="text-blue-500 hover:underline mt-2 inline-block">
                                <button className="border-2 font-medium rounded-lg p-2 w-full bg-blue-500 text-white hover:bg-blue-700">
                                    Postuler
                                </button>
                            </Link>
                        </div>
                    ))}
                </div>
            </section>
        );
    }
}
