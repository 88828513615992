import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import '../styles/Loading.css';

const Loading = () => {
    return (
        <div className="loading-container">
            <FaSpinner className="spinner-icon" />
            <p>Chargement...</p>
        </div>
    );
};

export default Loading;
