import React from 'react';
import { useNavigate } from 'react-router-dom';
import errorImage from '../assets/images/icons/error404.png';

const NotFound = () => {
    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/');
    };

    return (
        <section className="submit-area section-gap">
            <div className="text-center mt-10">
                <h1 className="text-4xl font-bold">Erreur 404</h1>
                <p className="text-lg">Page introuvable</p>
                <img
                    src={errorImage}
                    alt="Erreur 404"
                    className="mt-5 mx-auto"
                    style={{ maxWidth: '300px' }}
                />
                <button
                    onClick={handleRedirect}
                    className="mt-5 bg-blue-500 text-white py-2 px-4 rounded"
                >
                    🏠 Retour à l'accueil
                </button>
                <div className="mt-10" />
            </div>
        </section>
    );
};

export default NotFound;
