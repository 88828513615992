import React, { Component } from 'react';
import { AiOutlineSearch, AiFillCloseCircle } from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import Jobs from './Jobs';
import Value from './Value';
import { fetchVillesActives } from '../services/VilleService';
import { fetchSecteursActifs } from '../services/SecteurService';
import { fetchTypesActifs } from '../services/TypeService';
import { fetchContratsActifs } from '../services/TypeContratService';

export default class Search extends Component {
    constructor(props) {
        super(props);
        this.state = {
            villes: [],
            secteurs: [],
            types: [],
            contrats: [],
            selectedVille: '',
            selectedSecteur: '',
            selectedType: '',
            selectedContrat: '',
            loading: true,
            error: null,
        };
    }

    async componentDidMount() {
        await this.loadData();
    }

    loadData = async () => {
        try {
            const [villes, secteurs, types, contrats] = await Promise.all([
                fetchVillesActives(),
                fetchSecteursActifs(),
                fetchTypesActifs(),
                fetchContratsActifs()
            ]);
            this.setState({ villes, secteurs, types, contrats, loading: false });
        } catch (error) {
            this.setState({ error: error.message, loading: false });
        }
    }

    handleChange = (field) => (event) => {
        this.setState({ [field]: event.target.value });
    };

    render() {
        const { villes, secteurs, types, contrats, selectedVille, selectedSecteur, selectedType, selectedContrat, loading, error } = this.state;

        return (
            <div>
                <section className="Search p-6 rounded-lg">
                    <div className="bg-white p-4 rounded-lg shadow-lg">
                        <form>
                            <div className="flex flex-wrap gap-4">
                                {/* Sélectionner une ville */}
                                <div className="flex items-center flex-grow relative">
                                    <GoLocation className="absolute left-4 text-gray-500" />
                                    <select
                                        className="w-full pl-10 pr-6 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                        value={selectedVille}
                                        onChange={this.handleChange('selectedVille')}
                                    >
                                        <option value="" className="left-4">Sélectionner une ville...</option>
                                        {villes.map((ville) => (
                                            <option key={ville.id} value={ville.id}>
                                                {ville.title}
                                            </option>
                                        ))}
                                    </select>
                                    <AiFillCloseCircle className="absolute right-5 text-gray-500 cursor-pointer hover:text-black" onClick={() => this.setState({ selectedVille: '' })} />
                                </div>

                                {/* Sélectionner un secteur */}
                                {/* <div className="flex items-center flex-grow relative">
                                    <AiOutlineSearch className="absolute left-4 text-gray-500" />
                                    <select
                                        className="w-full pl-10 pr-5 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500 max-h-56 overflow-auto"
                                        value={selectedSecteur}
                                        onChange={this.handleChange('selectedSecteur')}
                                    >
                                        <option value="">Sélectionner un secteur...</option>
                                        {secteurs.map((secteur) => (
                                            <option key={secteur.id} value={secteur.id}>
                                                {secteur.title}
                                            </option>
                                        ))}
                                    </select>
                                    <AiFillCloseCircle className="absolute right-5 text-gray-500 cursor-pointer hover:text-black" onClick={() => this.setState({ selectedSecteur: '' })} />
                                </div> */}

                                {/* Sélectionner un type de contrat */}
                                <div className="flex items-center flex-grow relative">
                                    <AiOutlineSearch className="absolute left-4 text-gray-500" />
                                    <select
                                        className="w-full pl-10 pr-5 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
                                        value={selectedContrat}
                                        onChange={this.handleChange('selectedContrat')}
                                    >
                                        <option value="" className="left-4">Sélectionner un contrat...</option>
                                        {contrats.map((contrat) => (
                                            <option key={contrat.id} value={contrat.id}>
                                                {contrat.titre}
                                            </option>
                                        ))}
                                    </select>
                                    <AiFillCloseCircle className="absolute right-5 text-gray-500 cursor-pointer hover:text-black" onClick={() => this.setState({ selectedContrat: '' })} />
                                </div>

                                {/* Bouton de recherche */}
                                {/* <button className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg flex-grow transition-all duration-300">
                                    Rechercher
                                </button> */}
                            </div>
                        </form>
                        {/* {loading && <p className="text-blue-500 mt-4">Chargement des données...</p>} */}
                        {/* {error && <p className="text-red-500 mt-4">{error}</p>} */}
                    </div>
                </section>
                <Jobs />
                {/* <Value /> */}
            </div>
        );
    }
}
