// src/services/authService.js
import axios from 'axios';
import { API_URLS } from '../constants';

// Fonction pour la connexion
export const login = async (email, password) => {
    try {
        const response = await axios.post(API_URLS.LOGIN, { email, password });
        return response.data;
    } catch (error) {
        throw new Error('Erreur lors de la connexion : ' + error.message);
    }
};

// Fonction pour l'inscription
export const register = async (username, email, password, passwordConfirmation) => {
    try {
        const response = await axios.post(API_URLS.SIGNUP, {
            name: username,
            email,
            password,
            password_confirmation: passwordConfirmation,
        });
        return response.data;
    } catch (error) {
        throw new Error('Erreur lors de l\'inscription : ' + error.message);
    }
};