import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchCompanyById } from '../services/CompanieService';
import Loading from './Loading';
import '../styles/CompanyDetails.css';

const CompanyDetails = () => {
    const { id } = useParams();
    const [company, setCompany] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchCompany = async () => {
            try {
                const companyData = await fetchCompanyById(id);
                setCompany(companyData);
            } catch (err) {
                setError('Erreur lors de la récupération des détails de l\'entreprise');
            } finally {
                setLoading(false);
            }
        };

        fetchCompany();
    }, [id]);

    if (loading) return <Loading />;
    if (error) return <p>{error}</p>;

    return (
        <div className="company-details-container">
            {/* En-tête avec logo et nom de l'entreprise */}
            <div className="company-header">
                <div className="company-logo">
                    {company.logo ? (
                        <img src={`path-to-company-logo/${company.logo}`} alt={company.cname} />
                    ) : (
                        <div className="placeholder-logo">Logo indisponible</div>
                    )}
                </div>
                <div className="company-info">
                    <h2>{company.cname}</h2>
                    <p className="company-location">{company.address || 'Adresse non spécifiée'}</p>
                    <button className="follow-button">Suivre</button>
                </div>
            </div>

            {/* Section des offres d'emploi */}
            <div className="company-jobs">
                <h3>Jobs ({company.jobs_count})</h3>
                <div className="job-filters">
                    <input type="text" placeholder="Rechercher par job, mot-clé ou entreprise" />
                    <button>Type de job</button>
                    <button>Télétravail</button>
                    <button>Professions</button>
                </div>
                <ul className="job-list">
                    {company.jobs && company.jobs.length > 0 ? (
                        company.jobs.map((job) => (
                            <li key={job.id} className="job-item">
                                <h4>{job.title}</h4>
                                <p>{job.location}</p>
                                <p>{job.type}</p>
                                <p>{job.is_remote ? 'Télétravail possible' : 'Sur site'}</p>
                            </li>
                        ))
                    ) : (
                        <li className="job-item">Aucune offre d'emploi disponible.</li>
                    )}
                </ul>
            </div>

            {/* Section de présentation de l'entreprise */}
            <div className="company-presentation">
                <h3>Présentation</h3>
                <p>{company.description || 'Aucune description disponible.'}</p>
            </div>
        </div>
    );
};

export default CompanyDetails;
